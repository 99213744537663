/* HEADER */

.header {
    padding-top: 5vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center; 
    justify-content: center;
    height: 55%;
    width: auto;
}



/* BODY */

.container {
    position: relative;
    margin: 2em auto;
    width: 30vw;
    height: 30vw;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center; 
}

.container .spinBtn  {

    font-size: 24px; 
    padding: 14px 30px;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 1em;
    height: 2.5em;
    background: #EF287A;
    border-radius: 50%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    color:white;
    letter-spacing: 0.1em;
    cursor: pointer;
    user-select: none;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.container .wheel {
    position: absolute;
    top: 210;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(57, 57, 57);
    border-radius: 50%;
    overflow: hidden;
    transition: transform 6s ease-in-out;
    transform: rotate(21deg);
}

.container .wheel .segment {
    position: absolute;
    width: 50%;
    height: 50%;
    background: var(--clr);
    transform-origin: bottom right;
    transform: rotate(calc(45deg * var(--i) + 22.5deg));
    clip-path: polygon(0 0, 60% 0, 100% 100%, 0 60%);
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    font-size: 0.1px;
    cursor: pointer;
}

.container .wheel .segment div {
    position: absolute;
    margin-top: 30px;
    margin-left: 30px;
    width: 60%;
    height: 60%;
    transform: rotate(45deg);
    color: black;
    font-family: "Helvetica Neue", sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.content span {
    width: 100%;
    font-size: calc(0.5em + 2vh);
}

.segment-icon {
    width: 30%;
    height: 30%;
}

section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.wheel-notif img {
    width: 90%;
    height: 90%;
    align-items: center;
    justify-content: center;
    margin-top: 1.5em;
}

.spinBtn {
    border-radius: 50%;
    font-size: 24px; 
    padding: 14px 30px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    
    transform: translate(-50%, -50%);
    background: #EF287A;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    color: rgb(28, 28, 28);
    letter-spacing: 0.1em;
    cursor: pointer;
    user-select: none;
    animation: heartbeat 1s infinite; 
    text-align: center;
}

.square-container {
    background-color: #EF287A;
    width: 140px;
    height: 24px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    font-size: 90%;
    color: white;
    font-family: 'Helvetica Neue', sans-serif;
  
}


.pink-border {
    color: white; 
    text-shadow: -4px -3px #FF69B4; 
    transform: rotate(-3deg); 
}

.circle {
    border-radius: 50%;
    font-size: 24px; 
    padding: 14px 30px;
    margin-left: auto;
    margin-right: auto; 
}



/* FOOTER */

.footer {
    position: fixed;
    left: 0;
    bottom: 7%;
    width: 100%;
    text-align: center;
}

.container.extra-spacing {
    margin-top: 180px; /* Ajustez cette valeur selon vos besoins */
}



/* AUTRES */

.blur {
    filter: blur(5px);
    pointer-events: none; /* Désactiver les interactions avec les éléments floutés */
    user-select: none; /* Empêcher la sélection de texte */
}

.no-blur, .no-blur * {
    filter: none !important;
}



/* Assurer que le bouton n'est pas flou */
.spinBtn {
    filter: none !important;
    z-index: 1000; /* Assurez-vous que le bouton est au-dessus des autres éléments */
}


.confetti-left {
    position: fixed;
    top: 20%;
    left: 0;
    z-index: 1000; /* Assurez-vous que les confettis apparaissent au-dessus des autres éléments */
}

.confetti-right {
    position: fixed;
    top: 20%;
    right: 0;
    z-index: 1000; /* Assurez-vous que les confettis apparaissent au-dessus des autres éléments */
}

h1 {
    font-size: 8vh;
}

.ds-alert-group__header h6
{
    margin-left: 3vw;
}

.simple-arrow-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    animation: bounce 1s infinite;
}

.simple-arrow-rect {
    width: 10px;
    height: 50px;
    top: 2px;
    background-color: white;
    
    border: 2px solid #EF287A;
    border-bottom: 0px solid;
}

.simple-arrow-triangle {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 25px solid white;
    position: relative;
}

.simple-arrow-triangle:after {
    content: "";
    position: absolute;
    top: -28px; 
    left: -19px;
    width: 0;
    height: 0;
    border-left: 19px solid transparent;
    border-right: 19px solid transparent;
    border-top: 34px solid #EF287A;
    z-index: -1;
}

.arrow {
    position: absolute;
    bottom: 98%;
    left: 50%;
    transform: translateX(-50%);
    border-left: 1.0vw solid transparent;
    border-right: 1.0vw solid transparent;
    border-top: 2vh solid #EF287A;
    z-index: 2;
    animation: rotateArrow 1s linear 2;
}

.social-icons {
    margin-top: 10px;
}

.social-icons a {
    font-family: 'IBM Plex Sans', sans-serif;
    color: #001D4A;
    font-weight: 500;
    font-size: 1rem;
    text-decoration: none; /* Supprimer le soulignement du lien */
}

.social-icons a:hover {
    color: #FF0000; /* Couleur lorsque le lien est survolé par la souris (changez la couleur en fonction de vos préférences) */
}




.twitter-icon,
.linkedin-icon {
    color: #333;
    font-size: 3vw;
    margin-right: 20px; /* Espacement entre les icônes */
}

.twitter-icon:hover,
.linkedin-icon:hover {
    color: #00acee; /* Pour Twitter */
}

.linkedin-icon:hover {
    color: #0077b5; /* Pour LinkedIn */
}

.ds-alert {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    text-align: center;
    animation: grow 1s ease-in-out;
    transform-origin: center ;
  }




/* MEDIA => TELEPHONE */


@media only screen and (max-width: 900px) {
    /* Styles for screens with a maximum width of 768px (e.g., phones) */
    .header 
    {
        size: 70%;
    }
    .container {
        margin-top: 5vh;
        width: 80vw;
        height: 80vw;
    }

    .spinBtn 
    {
        width: 10vw;
        height: 10vw;
        font-size: 3vw;
        color: azure;
    }

    .arrow {
        padding-bottom: 0vw;
        border-left: 4vw solid transparent;
        border-right: 4vw solid transparent;

        border-top: 5vw solid #EF287A;
    }
    h1 {
        font-size: 9vw;
    }
    .twitter-icon,
    .linkedin-icon {
        color: #333;
        font-size: 8vw;
        margin-right: 20px; /* Espacement entre les icônes */
    }
}





/* ANIMATION  */



  @keyframes grow {
    0% {
      transform: translate(-50%, -50%) scale(0);
      top: 50% ;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      top: 50%;
      
    }
  }


  @keyframes rotateArrow {
    0% {
        transform: translateX(-50%) rotate(0deg); /* Aucune rotation initiale */
    }
    100% {
        transform: translateX(-50%) rotate(720deg); /* Rotation complète de 720 degrés (2 tours) */
    }
}

@keyframes heartbeat {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1); 
    }
    100% {
        transform: scale(1);
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    
    40% {
        transform: translateY(-20px);
    }
    
    60% {
        transform: translateY(-10px);
    }
}
